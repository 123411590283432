@import "popup";

@-webkit-keyframes pulsate {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.1;
  }
}

.map-wrapper {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 110px;
  right: 0;

  .leaflet-container {
    height: 100%;
  }

  &.hidden {
    z-index: $hidden;
  }
  &.show {
    z-index: $map;
  }
  &.narrative-mode {
    left: 0;
  }

  .event {
    fill: $event_default;
    cursor: pointer;
    opacity: 0.45;
  }

  .link {
    stroke: $midgrey;
    fill: none;
    stroke-width: 2;
    stroke-dasharray: 2px 5px;
  }

  .site-label {
    background: rgba($black, 0.6);
    color: #fff;
    padding: 5px;
    font-weight: 500;
    font-size: 11px;
    border: rgba($black, 0.6);
    letter-spacing: 0.05em;

    &::before {
      border-top-color: rgba($black, 0.6);
    }
  }

  .sites-layer,
  .shapes-layer {
    position: fixed;
    top: 0px;
    left: 110px;
  }

  &.narrative-mode {
    .sites-layer,
    .shapes-layer {
      position: fixed;
      top: 0px;
      left: 0px;
    }
  }
}

/*
* Leaflet mapping controls
*/
.leaflet-touch .leaflet-bar {
  .leaflet-control-zoom {
    border: 0;
    margin-left: 20px;
    margin-top: 20px;
  }

  a.leaflet-control-zoom-in,
  a.leaflet-control-zoom-out {
    border: 0;
    border-radius: 2px;
    color: $yellow;
  }

  a.leaflet-control-zoom-in {
    border-bottom: 1px solid $yellow;
  }
}

/*
* Leaflet marker and popups
*/

.leaflet-svg {
  display: block;

  &.hide {
    display: none;
  }

  &:focus {
    outline: none;
  }
}

.leaflet-popup {
  display: none;

  &.do-display {
    display: block;
  }
}

.leaflet-popup-content-wrapper {
  border-radius: 3px;
  background: $black;

  .leaflet-popup-content {
    color: white;
    margin: 0;
    padding: 3px 5px;

    .event-card {
      margin: 0;
    }
  }
}

.leaflet-popup-close-button {
  display: none;

  & + .leaflet-popup-content-wrapper .leaflet-popup-content {
    padding-top: 3px;
  }
}

.leaflet-popup-tip-container {
  display: none;
}

.leaflet-pane > svg path.bus-route,
.leaflet-pane > svg path.district {
  pointer-events: auto;
}

.eventLocationMarker {
  fill: none;
  stroke: $yellow;
  stroke-width: 2;
}

.leaflet-tile {
  // filter: brightness(110%) invert(100%) grayscale(800%) contrast(80%);
}

/*
*
* Elements
*/

.event-hover {
  opacity: 0;
}

.event-hover:hover {
  opacity: 1;
}

.narrative-mode {
}

.location-event {
  cursor: pointer;
}

.cluster-event {
  cursor: pointer;
}

.location-event-marker {
  pointer-events: all !important;
  fill: $event_default;
  stroke-width: 0;

  &.blue {
    fill: blue;
  }
}

.cluster-event-marker {
  pointer-events: all !important;

  &.red {
    fill: red;
  }
}

.narrative-step-arrow {
  pointer-events: all !important;
}

.path-polyline {
  stroke: $darkgrey;
  stroke-width: 2px;
}

.no-hover {
  cursor: grab;
}

// no hover styles for events when in narrative mode
.narrative-mode {
  .event-hover:hover {
    opacity: 0;
  }
  .no-hover {
    cursor: inherit;
  }
}
