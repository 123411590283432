@import "variables";

.satellite-overlay-toggle {
  background-color: rgb(53, 53, 53);
  opacity: 0.9;
  position: fixed;
  top: 0.5em;
  right: 0.5em;
  z-index: $map-overlay;
  cursor: pointer;

  .satellite-overlay-toggle-button {
    width: 64px;
    height: 64px;
    opacity: 0.8;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 0.5em;
    text-transform: uppercase;

    &.satellite-overlay-toggle-map {
      color: black;
    }

    &:hover {
      opacity: 1;
    }

    .label {
      font-size: $normal;
      font-family: $mainfont;
    }
  }
}
